const names = [
	'Agnieszka',
	'Alfreda',
	'Aniela',
    'Apollona',
    'Apollina',
    'Apolonia',
    'Arabella',
    'Ariadna',
    'Arleta',
    'Arnolda',
    'Astryda',
    'Atena',
    'Augusta',
    'Augustyna',
    'Aurelia',
    'Aurora',
    'Babeta',
    'Balbina',
    'Barbara',
    'Bartłomieja',
    'Beata',
    'Beatrycja',
    'Beatrycze',
    'Beatryksa',
    'Benedykta',
    'Beniamina',
    'Berenika',
    'Bernarda',
    'Bernadeta',
    'Berta',
    'Betina',
    'Bianka',
    'Bibiana',
    'Blanka',
    'Błażena',
    'Bogdana',
    'Bogna',
    'Boguchwała',
    'Bogumiła',
    'Bogusława',
    'Bojana',
    'Bolesława',
    'Bona',
    'Bożena',
    'Bromira',
    'Bronisława',
    'Brunhilda',
    'Brygida',
    'Cecylia',
    'Celestyna',
    'Celina',
    'Cezaria',
    'Chociemira',
    'Chwalisława',
    'Czesława',
    'Dajmira',
    'Dagna',
    'Dagmara',
    'Dalia',
    'Dalila',
    'Dalmira',
    'Damroka',
    'Dana',
    'Daniela',
    'Danisława',
    'Danuta',
    'Dargomira',
    'Dargosława',
    'Daria',
    'Dąbrówka',
    'Delia',
    'Diana',
    'Dilara',
    'Dobiesława',
    'Domasława',
    'Dominika',
    'Donata',
    'Dorosława',
    'Dorota',
    'Dyzma',
    'Dżanan',
    'Dżamila',
    'Dżesika',
    'Edyta',
    'Eleonora',
    'Eliza',
    'Elwira',
    'Elżbieta',
    'Elmira',
    'Emanuela',
    'Emilia',
    'Emina',
    'Emma',
    'Ernesta',
    'Ernestyna',
    'Eryka',
    'Estera',
    'Eugenia',
    'Ewa',
    'Ewelina',
    'Fabia',
    'Fabiana',
    'Fabiola',
    'Farida',
    'Fatima',
    'Fatma',
    'Faustyna',
    'Felicja',
    'Felicjana',
    'Felicyta',
    'Feliksa',
    'Ferdynanda',
    'Filipa',
    'Filipina',
    'Flora',
    'Floriana',
    'Franciszka',
    'Fryderyka',
    'Gabriela',
    'Gaja',
    'Genowefa',
    'Gerarda',
    'Gertruda',
    'Gizela',
    'Gloria',
    'Gniewomira',
    'Gracja',
    'Gracjana',
    'Grażyna',
    'Greta',
    'Gryzelda',
    'Grzymisława',
    'Gustawa',
    'Gwidona',
    'Hadriana',
    'Halina',
    'Halszka',
    'Hanna',
    'Hedwiga',
    'Helena',
    'Helga',
    'Henrieta',
    'Henryka',
    'Herma',
    'Hermana',
    'Hermenegilda',
    'Hermina',
    'Hestia',
    'Hiacynta',
    'Hilaria',
    'Hildegarda',
    'Hipolita',
    'Honorata',
    'Hortensja',
    'Huberta',
    'Ida',
    'Idosława',
    'Idzia',
    'Idalia',
    'Idzisława',
    'Iga',
    'Ildefonsa',
    'Ilia',
    'Iliana',
    'Ilona',
    'Ilza',
    'Inga',
    'Ingeborga',
    'Ingryda',
    'Irena',
    'Iryda',
    'Iwa',
    'Iwona',
    'Izabela',
    'Izolda',
    'Izydora',
    'Jadwiga',
    'Jagoda',
    'Jana',
    'Janina',
    'Jarmiła',
    'Jaromiła',
    'Jaromira',
    'Jarosława',
    'Jasława',
    'Jaśmina',
    'Joachima',
    'Joanna',
    'Jolanta',
    'Jowita',
    'Józefa',
    'Józefina',
    'Judyta',
    'Julia',
    'Julianna',
    'Julisława',
    'Julita',
    'Justyna',
    'Juta',
    'Kaja',
    'Kalina',
    'Kamila',
    'Karima',
    'Karina',
    'Karola',
    'Karolina',
    'Karyna',
    'Katarzyna',
    'Kasandra',
    'Kazimiera',
    'Kiara',
    'Kiliana',
    'Kinga',
    'Kira',
    'Klara',
    'Klarysa',
    'Klaudia',
    'Klaudyna',
    'Klementyna',
    'Kleopatra',
    'Klotylda',
    'Konstancja',
    'Kordula',
    'Kornelia',
    'Koryna',
    'Kosma',
    'Krystiana',
    'Krystyna',
    'Krzysztofa',
    'Ksawera',
    'Ksenia',
    'Kunegunda',
    'Kwiatosława',
    'Kwietosława',
    'Laila',
    'Lajla',
    'Lana',
    'Larysa',
    'Latifa',
    'Laura',
    'Laurenjca',
    'Lea',
    'Lejla',
    'Lena',
    'Leokadia',
    'Leona',
    'Leonarda',
    'Leoncja',
    'Leonora',
    'Leopolda',
    'Lidia',
    'Ligia',
    'Lilia',
    'Liliana',
    'Linda',
    'Liwia',
    'Lora',
    'Luborada',
    'Lucjana',
    'Lucjola',
    'Lucyna',
    'Ludmiła',
    'Ludolfa',
    'Ludwika',
    'Ludwina',
    'Luiza',
    'Luna',
    'Ładana',
    'Ładysława',
    'Łagoda',
    'Łucja',
    'Macieja',
    'Magda',
    'Magdalena',
    'Maja',
    'Maksa',
    'Maksyma',
    'Malina',
    'Malwina',
    'Małgorzata',
    'Manuela',
    'Marcela',
    'Marcjana',
    'Marcjanna',
    'Maria',
    'Mariam',
    'Marianna',
    'Marietta',
    'Marika',
    'Mariola',
    'Marlena',
    'Marta',
    'Martyna',
    'Maryja',
    'Maryla',
    'Maryna',
    'Marzena',
    'Matylda',
    'Melania',
    'Michalina',
    'Mieczysława',
    'Milena',
    'Milomira',
    'Miłosława',
    'Miłowita',
    'Mira',
    'Mirabela',
    'Miranda',
    'Mirela',
    'Miriam',
    'Mirka',
    'Miroda',
    'Mirołada',
    'Mirosława',
    'Mojmira',
    'Monika',
    'Morzana',
    'Morzena',
    'Nadia',
    'Nadzieja',
    'Najmiła',
    'Najsława',
    'Narcyza',
    'Natalia',
    'Natasza',
    'Nela',
    'Nika',
    'Nikodema',
    'Nikola',
    'Nikoleta',
    'Nina',
    'Nora',
    'Norberta',
    'Norma',
    'Oda',
    'Odeta',
    'Odyla',
    'Ofelia',
    'Oksana',
    'Oktawia',
    'Ola',
    'Olga',
    'Olimpia',
    'Oliwia',
    'Oriana',
    'Ota',
    'Otylia',
    'Ożanna',
    'Olena',
    'Pabiana',
    'Pamela',
    'Patrycja',
    'Paula',
    'Paulina',
    'Pelagia',
    'Petra',
    'Petronela',
    'Petronia',
    'Placyda',
    'Pola',
    'Polmira',
    'Przemysława',
    'Przybysława',
    'Rachela',
    'Ramona',
    'Radmiła',
    'Radomiła',
    'Radomira',
    'Radosława',
    'Rafaela',
    'Rajmunda',
    'Rajna',
    'Raszyda',
    'Rebeka',
    'Regina',
    'Remigia',
    'Renata',
    'Rita',
    'Roberta',
    'Rodzisława',
    'Roksana',
    'Roma',
    'Romana',
    'Romualda',
    'Rozalia',
    'Rozalinda',
    'Rozamunda',
    'Rozetta',
    'Rozwita',
    'Róża',
    'Rudolfina',
    'Ruta',
    'Ryszarda',
    'Sabina',
    'Safira',
    'Salma',
    'Saloma',
    'Salomea',
    'Samanta',
    'Sandra',
    'Sara',
    'Sebastiana',
    'Selena',
    'Selma',
    'Serafina',
    'Seweryna',
    'Sędomira',
    'Sędzisława',
    'Sława',
    'Sławina',
    'Sławomira',
    'Sobiesława',
    'Sonia',
    'Stamira',
    'Stanisława',
    'Stefania',
    'Stela',
    'Stoisława',
    'Stella',
    'Sydney',
    'Strzeżymira',
    'Subisława',
    'Sulima',
    'Sulisława',
    'Sybilla',
    'Sylwana',
    'Sylwia',
    'Szarlota',
    'Szarlin',
    'Szarlina',
    'Szejma',
    'Szymona',
    'Świetlana',
    'Świętomira',
    'Świętosława',
    'Tabita',
    'Tacjana',
    'Tadea',
    'Tamara',
    'Tatiana',
    'Tekla',
    'Telimena',
    'Teodora',
    'Teodozja',
    'Teresa',
    'Tęgomira',
    'Tina',
    'Tolisława',
    'Tomiła',
    'Tomisława',
    'Tulimira',
    'Ulana',
    'Ulryka',
    'Uma',
    'Una',
    'Unima',
    'Urszula',
    'Uta',
    'Wacława',
    'Walentyna',
    'Waleria',
    'Wanda',
    'Wanessa',
    'Wera',
    'Weronika',
    'Wesna',
    'Wiara',
    'Wielina',
    'Wiera',
    'Wierada',
    'Wiesława',
    'Wiktoria',
    'Wilhelmina',
    'Wilma',
    'Wincentyna',
    'Wioleta',
    'Wirgilia',
    'Wirginia',
    'Wisława',
    'Witosława',
    'Władysława',
    'Włodzimiera',
    'Wolimira',
    'Wrocisława',
    'Zaida',
    'Zaira',
    'Zdzisława',
    'Zefiryna',
    'Zenobia',
    'Zenona',
    'Zofia',
    'Zuzanna',
    'Zwinisława',
    'Zygfryda',
    'Zyta',
    'Żaklina',
    'Żaneta',
    'Żanna',
    'Żelisława',
    'Żużanna',
    'Żywia',
    'Żywisława',
    'Abdon',
    'Abel',
    'Abelard',
    'Abraham',
    'Achilles',
    'Achmed',
    'Adam',
    'Adelard',
    'Adnan',
    'Adrian',
    'Agapit',
    'Agaton',
    'Agrypin',
    'Ajdin',
    'Albert',
    'Albin',
    'Albrecht',
    'Aleksander',
    'Aleksy',
    'Alfons',
    'Alfred',
    'Alojzy',
    'Alwin',
    'Amadeusz',
    'Ambroży',
    'Anastazy',
    'Anatol',
    'Andrzej',
    'Anioł',
    'Annasz',
    'Antoni',
    'Antonin',
    'Antonius',
    'Anzelm',
    'Apollo',
    'Apoloniusz',
    'Ariel',
    'Arkadiusz',
    'Arkady',
    'Arnold',
    'Aron',
    'Artur',
    'August',
    'Augustyn',
    'Aurelian',
    'Baldwin',
    'Baltazar',
    'Barabasz',
    'Barnim',
    'Bartłomiej',
    'Bartosz',
    'Bazyli',
    'Beat',
    'Benedykt',
    'Beniamin',
    'Benon',
    'Bernard',
    'Bert',
    'Błażej',
    'Bodosław',
    'Bogdał',
    'Bogdan',
    'Boguchwał',
    'Bogumił',
    'Bogumir',
    'Bogusław',
    'Bogusz',
    'Bolebor',
    'Bolelut',
    'Bolesław',
    'Bonawentura',
    'Bonifacy',
    'Borys',
    'Borysław',
    'Borzywoj',
    'Bożan',
    'Bożidar',
    'Bożydar',
    'Bożimir',
    'Bromir',
    'Bronisław',
    'Bruno',
    'Brunon',
    'Budzisław',
    'Cecyl',
    'Cecylian',
    'Celestyn',
    'Cezar',
    'Cezary',
    'Chociemir',
    'Chrystian',
    'Chwalibóg',
    'Chwalimir',
    'Chwalisław',
    'Cichosław',
    'Cyprian',
    'Cyryl',
    'Czesław',
    'Dajmir',
    'Dal',
    'Dalbor',
    'Damazy',
    'Damian',
    'Daniel',
    'Danisław',
    'Danko',
    'Dargomir',
    'Dargosław',
    'Dariusz',
    'Darwit',
    'Dawid',
    'Denis',
    'Derwit',
    'Dionizy',
    'Dobiesław',
    'Dobrogost',
    'Dobrosław',
    'Domasław',
    'Dominik',
    'Donald',
    'Donat',
    'Dorian',
    'Duszan',
    'Dymitr',
    'Dyter',
    'Dżamil',
    'Dżan',
    'Dżem',
    'Dżemil',
    'Edmund',
    'Edward',
    'Edwin',
    'Efrem',
    'Eliasz',
    'Eligiusz',
    'Emanuel',
    'Emil',
    'Emir',
    'Erazm',
    'Ernest',
    'Erwin',
    'Eugeniusz',
    'Eryk',
    'Ewald',
    'Ewaryst',
    'Ezaw',
    'Ezechiel',
    'Fabian',
    'Farid',
    'Faris',
    'Faustyn',
    'Felicjan',
    'Feliks',
    'Ferdynand',
    'Filip',
    'Florentyn',
    'Florian',
    'Fortunat',
    'Franciszek',
    'Fryc',
    'Fryderyk',
    'Gabriel',
    'Gaj',
    'Gardomir',
    'Gaweł',
    'Gerard',
    'Gerwazy',
    'Gilbert',
    'Ginter',
    'Gniewomir',
    'Godfryg',
    'Godfryd',
    'Godzisław',
    'Gościsław',
    'Gracjan',
    'Grodzisław',
    'Grzegorz',
    'Grzymisław',
    'Gustaw',
    'Gwalbert',
    'Gwido',
    'Gwidon',
    'Hadrian',
    'Hamza',
    'Hanusz',
    'Hasan',
    'Hektor',
    'Heliodor',
    'Henryk',
    'Herakles',
    'Herbert',
    'Herman',
    'Hermes',
    'Hiacynt',
    'Hieronim',
    'Hilary',
    'Hipolit',
    'Honorat',
    'Horacy',
    'Hubert',
    'Hugo',
    'Hugon',
    'Husajn',
    'Idzi',
    'Ignacy',
    'Igor',
    'Ildefons',
    'Inocenty',
    'Ireneusz',
    'Iwan',
    'Iwo',
    'Iwon',
    'Izajasz',
    'Izydor',
    'Jacek',
    'Jacenty',
    'Jakub',
    'Jan',
    'January',
    'Janusz',
    'Jarad',
    'Jaromir',
    'Jaropełk',
    'Jarosław',
    'Jarowit',
    'Jeremiasz',
    'Jerzy',
    'Jędrzej',
    'Joachim',
    'Jona',
    'Jonasz',
    'Jonatan',
    'Jozafat',
    'Józef',
    'Józefat',
    'Julian',
    'Juliusz',
    'Jur',
    'Juri',
    'Justyn',
    'Justynian',
    'Jasuf',
    'Kacper',
    'Kain',
    'Kajetan',
    'Kajfasz',
    'Kajusz',
    'Kamil',
    'Kanimir',
    'Karol',
    'Kasjusz',
    'Kasper',
    'Kastor',
    'Kazimierz',
    'Kemal',
    'Kilian',
    'Klaudiusz',
    'Klemens',
    'Kochan',
    'Kondrat',
    'Konrad',
    'Konradyn',
    'Konstancjusz',
    'Konstanty',
    'Konstantyn',
    'Kordian',
    'Kornel',
    'Korneli',
    'Korneliusz',
    'Kosma',
    'Kryspyn',
    'Krystian',
    'Krystyn',
    'Krzesisław',
    'Krzysztof',
    'Ksawery',
    'Kwiatosław',
    'Kwietosław',
    'Lambert',
    'Laurencjusz',
    'Lech',
    'Lechosław',
    'Lenart',
    'Leo',
    'Leon',
    'Leokadiusz',
    'Leonard',
    'Leopold',
    'Lesław',
    'Leszek',
    'Lew',
    'Longin',
    'Lubisław',
    'Lubogost',
    'Lubomił',
    'Lubomir',
    'Luborad',
    'Lubosław',
    'Lucjan',
    'Ludmił',
    'Ludomił',
    'Ludolf',
    'Ludomir',
    'Ludowit',
    'Ludwik',
    'Ładysław',
    'Łazarz',
    'Łucjan',
    'Łukasz',
    'Maciej',
    'Magnus',
    'Makary',
    'Maksymilian',
    'Malachiasz',
    'Mamert',
    'Manfred',
    'Manuel',
    'Marcel',
    'Marceli',
    'Marcin',
    'Marcjan',
    'Marek',
    'Marian',
    'Marin',
    'Mariusz',
    'Maryn',
    'Mateusz',
    'Maurycjusz',
    'Maurycy',
    'Maurycjusz',
    'Medard',
    'Melchior',
    'Metody',
    'Michał',
    'Mieszko',
    'Mieczysław',
    'Mikołaj',
    'Milan',
    'Miłobąd',
    'Miłogost',
    'Miłomir',
    'Miłorad',
    'Miłosław',
    'Miłowan',
    'Miłowit',
    'Miłosz',
    'Miłowit',
    'Mirod',
    'Miroład',
    'Miron',
    'Mirosław',
    'Mirosz',
    'Modest',
    'Mojmierz',
    'Mojmir',
    'Mojżesz',
    'Mściwoj',
    'Muhamed',
    'Murat',
    'Myślimir',
    'Napoleon',
    'Narcyz',
    'Nasif',
    'Natan',
    'Natanael',
    'Nataniel',
    'Nestor',
    'Nicefor',
    'Niecisław',
    'Nikodem',
    'Norbert',
    'Norman',
    'Odo',
    'Odon',
    'Oktawian',
    'Oktawiusz',
    'Olaf',
    'Oleg',
    'Olgierd',
    'Omar',
    'Onufry',
    'Oskar',
    'Orian',
    'Otniel',
    'Oswald',
    'Otokar',
    'Otto',
    'Otton',
    'Owidiusz',
    'Pabian',
    'Pafnucy',
    'Pakosław',
    'Pankracy',
    'Paskal',
    'Patrycjusz',
    'Patryk',
    'Paweł',
    'Pelagiusz',
    'Petroniusz',
    'Piotr',
    'Placyd',
    'Polikarp',
    'Prokop',
    'Prot',
    'Protazy',
    'Przemysł',
    'Przemysław',
    'Przedpełk',
    'Przybysław',
    'Radogost',
    'Radomił',
    'Radomir',
    'Radosław',
    'Radowit',
    'Radzimir',
    'Rafał',
    'Rajmund',
    'Rajner',
    'Remigiusz',
    'Renat',
    'Robert',
    'Roch',
    'Roderyk',
    'Roger',
    'Roland',
    'Roman',
    'Romeo',
    'Romuald',
    'Ronald',
    'Rosłan',
    'Rudolf',
    'Rufus',
    'Ryszard',
    'Salomon',
    'Samir',
    'Sambor',
    'Samson',
    'Samuel',
    'Sebastian',
    'Serafin',
    'Sergiusz',
    'Serwacy',
    'Seweryn',
    'Sędomir',
    'Sędzisław',
    'Siemowit',
    'Sław',
    'Sławek',
    'Sławomierz',
    'Sławomir',
    'Sobiesław',
    'Sofroniusz',
    'Stanisław',
    'Starwit',
    'Stefan',
    'Stoigniew',
    'Stoisław',
    'Stojan',
    'Strzeżymir',
    'Subisław',
    'Sulibor',
    'Sulisław',
    'Sykstus',
    'Sylwan',
    'Sylwester',
    'Sylwiusz',
    'Symeon',
    'Syriusz',
    'Szczepan',
    'Szymon',
    'Ścibor',
    'Świętibor',
    'Świętomir',
    'Świętopełk',
    'Świętosław',
    'Tadeusz',
    'Tarik',
    'Telesfor',
    'Telimena',
    'Teobald',
    'Teodor',
    'Teodozjusz',
    'Teofil',
    'Tęgomir',
    'Tobiasz',
    'Tomasz',
    'Tomisław',
    'Tristan',
    'Tulimir',
    'Tulimierz',
    'Tymon',
    'Tymoteusz',
    'Tytus',
    'Urban',
    'Ursyn',
    'Wacław',
    'Wahid',
    'Waldemar',
    'Walenty',
    'Walentyn',
    'Walerian',
    'Walery',
    'Walid',
    'Walter',
    'Wandelin',
    'Wawrzyniec',
    'Więcesław',
    'Wenancjusz',
    'Wendelin',
    'Wespazjan',
    'Wielisław',
    'Wiesław',
    'Wiktor',
    'Wilhelm',
    'Wincenty',
    'Wirgiliusz',
    'Wirginiusz',
    'Wisław',
    'Wit',
    'Witold',
    'Witołd',
    'Witosław',
    'Władysław',
    'Włodzimierz',
    'Włodzisław',
    'Wojciech',
    'Wolimir',
    'Wojsław',
    'Wrocisław',
    'Wszebor',
    'Zachariasz',
    'Zbigniew',
    'Zdzisław',
    'Zenobiusz',
    'Zefir',
    'Zefiryn',
    'Zenon',
    'Ziemowit',
    'Zwinisław',
    'Zygfryd',
    'Zygmunt',
    'Żarek',
    'Żarko',
    'Żelisław',
    'Żytomir'
]

export const isName = name => names.includes(name);
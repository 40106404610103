<script>
	import { fly } from 'svelte/transition';
	import { getIntentions } from "./Components/Data";
	import { isName } from './Components/Names';
	import Loader from "./Components/Loader.svelte";

	let intentionsData;
	let errorData;
	setTimeout(() => {
		getIntentions().then((data) => {
			intentionsData = data;
			if (data.error_code) {
				errorData = data.error_code;
			}
		})
		.catch(error => {
			errorData = error;
		})
	}, 1000)

	function formatEntry(entry) {
		const entryWords = entry.trim().split(' ');
		let outputString = '';
		let skipRest = false;
		if (entryWords[0].trim() == '+' || entryWords[0].trim() == '++') {
			entryWords.forEach(word => {
				if ((word == 'od') || (word == '(od')) skipRest = true;
				if (!skipRest) {
					const outputWord = isName(word.replace(',', '')) ? `<span class="firstname">${word}</span> ` : `${word} `;
					outputString += outputWord;
				} else {
					outputString += `${word} `;
				}
			})
		} else {
			outputString = entry;
		}
		return outputString;
	}

	function printIntentions() {
		window.print();
	}
</script>

<section class="hello">
	{#if intentionsData && !errorData}
		<div class="success" in:fly={{ x: 200, duration: 1000 }}>
			<img src="https://parafiamistrzejowice.pl/wp-content/uploads/2021/02/nav-logo.png" alt="">
			<h1>Arkusz intencji jest gotowy do wydruku!</h1>
			<h4>Zakres dat: <i>{new Date(intentionsData[0].date).toLocaleDateString('pl', {day: "numeric", month: "long", weekday: "long" })}</i> - <i>{new Date(intentionsData[intentionsData.length - 1].date).toLocaleDateString('pl', {day: "numeric", month: "long", weekday: "long" })}</i></h4>
			<button on:click={printIntentions} in:fly={{ x: 200, duration: 1000 }}><img class="printer" src="./favicon.png" alt="printer">Wydrukuj to dzieło!</button>
		</div>
	{:else if errorData}
		<img class="error_gif" src="/error.gif" alt={errorData}>
		<h4>Przykro nam, ale nie mogliśmy pobrać intencji mszalnych z serwera.</h4>
		<small>Kod błędu: {errorData} - przekaż go administratorowi systemu.</small>
	{:else}
		<div class="loader">
			<Loader />
			<h2>Czekaj, generujemy plik gotowy do wydruku!</h2>
		</div>
	{/if}
</section>

<section class="intentions">
	{#if intentionsData && !errorData}
		{#each intentionsData as { date, masses }}
			<div class="heading">
				<img src="https://parafiamistrzejowice.pl/wp-content/uploads/2021/02/nav-logo.png" alt="">
				<div>
					<h2>Intencje mszalne</h2>
					<small></small>
				</div>
			</div>
			<table class="tg">
				<tbody>
					<tr>
						<th class="tg-73oq time" colspan="7">{new Date(date).toLocaleDateString('pl', {day: "numeric", month: "long", weekday: "long" })}</th>
					</tr>
					{#each masses[0] as { hour, intentions }}
						<tr>
							<td class="tg-73oq hour" colspan="2" rowspan={intentions.length + 1}>
								{hour}
							</td>
						</tr>
						{#each intentions as entry, index}
							<tr>
								<td class="tg-73oq index">{index + 1}.</td>
								<td class="tg-73oq entry" colspan="4">{@html formatEntry(entry)}</td>
							</tr>
						{/each}
						
					{/each}
				</tbody>
			</table>
			<div class="summary">
				<small>Niniejszy arkusz intencji został wygenerowany przy pomocy systemu <b>parafiamistrzejowice.pl</b></small>
				<small>System zarządzania parafią stworzony przez: <b>Mateusz Rojek</b></small>
			</div>
		{/each}
	{/if}
</section>

<style>
	@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
	img {
		width: 150px;
		margin-right: 1rem;
	}
	.hour {
		font-weight: 600;
	}
	.summary {
		margin: 2rem 0 !important;
	}
	button {
		margin-top: 1rem;
		font-size: 1.2rem;
		border-radius: 6px;
		font-family: Montserrat;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-top: 2rem !important;
	}
	.printer {
		height: 1.5rem;
		width: 1.5rem;
		margin: 0 !important;
		margin-right: .3rem !important;
	}
	.loader,
	.hello {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100vh;
		text-align: center;
	}
	.success {
		text-align: center;
	}
	.success img {
		margin-bottom: 1rem;
	}
	.error_gif {
		height: 250px;
		width: auto;
		margin-bottom: 1rem;
	}
	.time,
	.hour,
	.entry,
	.index {
		font-family: Montserrat !important;
		font-size: 1.2rem;
		line-height: 1.5rem;
	}
	.time {
		font-size: 1.2rem;
	}
	small {
		display: block;
		font-size: .65rem;
		color: #929292;
	}
	h1 {
		font-family: Montserrat !important;
		font-size: 3rem;
		margin: .2rem 0;
	}
	@media (max-width: 768px) {
		h1 {
			font-size: 2rem;
		}
	}
	h2 {
		font-family: Montserrat !important;
		margin: 0;
		margin-bottom: .1rem;
	}
	h4 {
		font-family: Montserrat !important;
		font-size: 1.2rem;
		margin: 1rem 0;
	}
	.intentions {
		margin: 0 auto;
		width: 80vw;
		display: none;
	}
	@media (max-width: 768px) {
		.intentions {
			width: 95vw;
		}
	}
	@media print {
		.intentions {
			display: block;
		}
	}
	@media print {
		.hello {
			display: none;
		}
	}
	table {
		width: 100%;
		margin-bottom: 1rem;
	}
	tbody {
		width: 100%;
	}
	.heading {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		font-family: Montserrat !important;
		page-break-before: always;
	}
	.tg  {border-collapse:collapse;border-color:#aaa;border-spacing:0;}
	.tg td{background-color:#fff;border-color:#aaa;border-style:solid;border-width:1px;color:#333;
	overflow:hidden;padding:10px 5px;word-break:normal;}
	.tg th{background-color:#f38630;border-color:#aaa;border-style:solid;border-width:1px;color:#fff;
	overflow:hidden;padding:10px 5px;word-break:normal;}
	.tg .tg-73oq{border-color:#000000;text-align:left;vertical-align:top}
</style>
